<template>
  <div class="app">
    <!-- drawer -->
    <v-app-bar-nav-icon
      style="position: fixed; z-index: 5"
      v-if="$vuetify.breakpoint.mobile"
      @click="[(mini = !mini), (drawer = !drawer)]"
      color="secondary"
    ></v-app-bar-nav-icon>
    <v-navigation-drawer
      color="#17182A"
      class="drawer"
      :class="$vuetify.breakpoint.mobile ? 'pt-10' : ''"
      v-model="drawer"
      :permanent="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
      :temporary="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      fixed
      width="230px"
    >
      <div class="text-center px-3 py-3">
        <span class="white--text"
          >Jedo <span class="secondary--text">Admin</span></span
        >
        <span v-if="isDebug" class="red--text"> Debug </span>
      </div>
      <div class="white--text ma-2">
        <base-select
          v-model="$root.country"
          :items="$root.countries"
          placeholder="Country"
          :returnObject="true"
          itemValue="_id"
          itemText="name"
        ></base-select>
        <base-select
          v-model="$root.language"
          :items="$root.languages"
          placeholder="Language"
          :returnObject="false"
        ></base-select>
      </div>
      <v-divider class="divider-light"></v-divider>
      <div class="px-2 mt-4">
        <!-- menu links -->
        <span class="d-block grey--text font-12">MENU</span>
        <v-list dark expand>
          <v-list-item-group>
            <template v-for="(tab, i) in tabs">
              <v-list-item
                class="px-2"
                :key="i"
                v-if="tab.children && tab.children.length == 0"
                @click="itemSelected(tab)"
              >
                <div class="d-flex align-center">
                  <img width="20px" height="20px" :src="tab.icon" alt="" />
                </div>
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link
                      class="drawer-menu py-2 cursor-pointer px-2"
                      :to="tab.to"
                      tag="div"
                    >
                      <div class="py-1">
                        <span
                          class="white--text font-14"
                          :class="tab.active ? 'secondary--text' : ''"
                          >{{ tab.title }}</span
                        >
                      </div>
                    </router-link>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-group
                v-if="tab.children && tab.children.length > 0"
                :key="i"
                :value="tab.active"
                color="white"
                class="list-item-group--active"
                @click="itemSelected(tab)"
              >
                <template v-slot:activator>
                  <v-list-item-title>
                    <div class="py-1 d-flex align-center">
                      <img
                        class="mr-3"
                        width="20px"
                        height="20px"
                        :src="tab.icon"
                        alt=""
                      />
                      <span class="white--text font-14 d-inline-block">{{
                        tab.title
                      }}</span>
                    </div>
                  </v-list-item-title>
                </template>

                <div>
                  <div class="drawer-menu my-2 cursor-pointer px-2">
                    <template v-for="(child, j) in tab.children">
                      <v-list-item
                        v-if="child.visible"
                        :key="j"
                        @click="childSelected(child, tab)"
                        class="px-3 radius-5"
                      >
                        <span
                          class="white--text font-14"
                          :class="child.active ? 'secondary--text' : ''"
                          >{{ child.title }}
                        </span>
                      </v-list-item>
                    </template>
                  </div>
                </div>
              </v-list-group>
            </template>
          </v-list-item-group>
        </v-list>

        <!-- logout -->
        <div class="mt-1 px-2 py-1 cursor-pointer" @click="logout">
          <span class="white--text font-14">Logout</span>
        </div>
      </div>

      <div class="drawer-footer px-3 pt-3">
        <p class="white--text font-12 mb-1">© Jedo App 2023</p>
        <p class="grey--text font-11">With ❤️ by Team Jedo</p>
      </div>
    </v-navigation-drawer>

    <!-- content -->
    <v-main class="ml-auto" :class="mini ? 'main-mini' : ''">
      <div class="content">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    drawer: false,
    mini: false,
    selectedItem: null,
    tabs: [
      {
        title: "Dashboard",
        to: "/",
        routeName: "Dashboard",
        role: "All",
        icon: require("@/assets/images/icons/side/home.svg"),
        children: [],
        active: false
      },
      // {
      //   title: "Sales & Data analysis",
      //   to: "/sales",
      //   route: true,
      //   active: false,
      //   routeName: "Sales",
      //   visible: false,
      //   role: "All",
      //   icon: require("@/assets/images/icons/side/sales.png"),
      //   children: [],
      // },
      {
        title: "Bookings",
        to: "",
        route: false,
        active: true,
        routeName: "",
        visible: false,
        role: "all",
        icon: require("@/assets/images/icons/side/bookings.png"),
        children: [
          {
            title: "Discounted Guesthouse",
            to: "/discount-experiences",
            route: true,
            active: false,
            routeName: "Discounted",
            visible: false,
            role: "Experiences, read"
          },
          {
            title: "Custom",
            to: "/custom-bookings",
            route: true,
            active: false,
            routeName: "Custom",
            visible: false,
            role: "Experiences, read"
          },
          {
            title: "Adventures",
            to: "/bookings",
            route: true,
            active: false,
            routeName: "Adventures",
            visible: false,
            role: "Bookings, read"
          },
          {
            title: "Library",
            to: "/library/bookings",
            route: true,
            active: false,
            routeName: "LibraryBookings",
            visible: false,
            role: "Bookings, read"
          }
        ]
      },
      {
        title: "Experiences",
        to: "",
        route: true,
        active: true,
        routeName: "",
        visible: false,
        role: "",
        icon: require("@/assets/images/icons/side/experiences.svg"),
        children: [
          {
            title: "Experiences",
            to: "/experiences",
            route: true,
            active: false,
            routeName: "Experiences",
            visible: false,
            role: "Experiences, read"
          },
          {
            title: "Custom Experiences",
            to: "/custom-experiences",
            route: true,
            active: false,
            routeName: "Custom Experiences",
            visible: false,
            role: "Experiences, read"
          },
          {
            title: "Discounted Guesthouses",
            to: "/discounted-guesthouses",
            route: true,
            active: false,
            routeName: "Discounted Guesthouses",
            visible: false,
            role: "Experiences, read"
          },
          {
            title: "Guesthouses",
            to: "/guesthouses",
            route: true,
            active: false,
            routeName: "Guesthouses",
            visible: false,
            role: "Experiences, read"
          },
          {
            title: "Stats",
            to: "/activity-stats",
            route: true,
            active: false,
            routeName: "Activity Stats",
            visible: false,
            role: "Stats, read"
          },
          {
            title: "Districts",
            to: "/districts",
            route: true,
            active: false,
            routeName: "Districts",
            visible: false,
            role: "Experiences, read"
          },
          {
            title: "Locations",
            to: "/locations",
            route: true,
            active: false,
            routeName: "Locations",
            visible: false,
            role: "Experiences, read"
          },
          {
            title: "Experience Categories",
            to: "/categories",
            route: true,
            active: false,
            routeName: "Categories",
            visible: false,
            role: "Categories, read"
          }
        ]
      },
      {
        title: "Attractions",
        to: "",
        route: true,
        active: true,
        routeName: "",
        visible: false,
        role: "",
        icon: require("@/assets/images/icons/side/attractions.svg"),
        children: [
          {
            title: "Attractions",
            to: "/attractions",
            route: true,
            active: false,
            routeName: "Attractions",
            visible: false,
            role: "Miniguides, read"
          },
          {
            title: "Collections",
            to: "/collections",
            route: true,
            active: false,
            routeName: "Collections",
            visible: false,
            role: "Collections, read"
          },
          {
            title: "Attraction Categories",
            to: "/attraction-categories",
            route: true,
            active: false,
            routeName: "Attraction Categories",
            visible: false,
            role: "Categories, read"
          },
          {
            title: "Filters",
            to: "/filters",
            route: true,
            active: false,
            routeName: "Filters",
            visible: false,
            role: "Filters, read"
          }
        ]
      },
      {
        title: "Villages",
        to: "",
        route: true,
        active: true,
        routeName: "",
        visible: false,
        role: "",
        icon: require("@/assets/images/icons/side/attractions.svg"),
        children: [
          {
            title: "Villages",
            to: "/villages",
            route: true,
            active: false,
            routeName: "Villages",
            visible: false,
            role: "Villages, read"
          }
        ]
      },
      {
        title: "Library",
        to: "",
        route: true,
        active: true,
        routeName: "",
        visible: false,
        role: "",
        icon: require("@/assets/images/icons/side/experiences.svg"),
        children: [
          {
            title: "Trips",
            to: "/library/trips",
            route: true,
            active: false,
            routeName: "LibraryTrips",
            visible: false,
            role: "Experiences, read"
          },
          {
            title: "Sections",
            to: "/library/sections",
            route: true,
            active: false,
            routeName: "LibrarySections",
            visible: false,
            role: "Experiences, read"
          }
        ]
      },
      {
        title: "Scraper",
        to: "",
        route: true,
        active: true,
        routeName: "",
        visible: false,
        role: "",
        icon: require("@/assets/images/icons/side/experiences.svg"),
        children: [
          {
            title: "Scraper",
            to: "/scraper",
            route: true,
            active: false,
            routeName: "Scraper",
            visible: false,
            role: "Experiences, read"
          }
        ]
      },
      {
        title: "Recycle",
        to: "",
        route: true,
        active: true,
        routeName: "",
        visible: false,
        role: "",
        icon: require("@/assets/images/icons/delete.svg"),
        children: [
          {
            title: "Deleted Experiences",
            to: "/deleted-experiences",
            route: true,
            active: false,
            routeName: "Deleted Experiences",
            visible: true,
            role: "Deleted Experiences, read"
          },
          {
            title: "Deleted Attractions",
            to: "/deleted-attractions",
            route: true,
            active: false,
            routeName: "Deleted Attractions",
            visible: false,
            role: "Villages, read"
          },
          {
            title: "Deleted Discounted Guesthouses",
            to: "/deleted-discounted-guesthouses",
            route: true,
            active: false,
            routeName: "Deleted Discounted Guesthouses",
            visible: false,
            role: "Villages, read"
          },
          {
            title: "Deleted Villages",
            to: "/deleted-villages",
            route: true,
            active: false,
            routeName: "Deleted Villages",
            visible: false,
            role: "Villages, read"
          }
        ]
      },
      {
        title: "Users",
        to: "/users",
        route: true,
        active: false,
        routeName: "Users",
        visible: false,
        role: "Users, read",
        icon: require("@/assets/images/icons/side/users.svg"),
        children: []
      },
      {
        title: "Coupon",
        to: "/coupon",
        route: true,
        active: false,
        routeName: "Coupon",
        visible: false,
        role: "Coupons, read",
        icon: require("@/assets/images/icons/side/coupons.svg"),
        children: []
      },
      {
        title: "Hosts",
        to: "/hosts",
        route: true,
        active: false,
        routeName: "Hosts",
        visible: false,
        role: "Hosts, read",
        icon: require("@/assets/images/icons/side/users.svg"),
        children: []
      },
      {
        title: "Notifications",
        to: "/notifications",
        route: true,
        active: false,
        routeName: "Notifications",
        visible: false,
        role: "Notifications, add",
        icon: require("@/assets/images/icons/side/notifications.svg"),
        children: []
      }
    ],
    faqItemActive: false,
    routeName: "",
    visible: false,

    exCreateEnable: false,
    experienceCreate: { Experiences: "add" },
    searchKey: "",
    searchResults: [],
    resultsCount: 0,
    currentPage: 1,
    collectionType: "",

    // permissions
    collectionAdd: { Collections: "add" },
    miniguidesAdd: { Miniguides: "add" },
    miniguidesRead: { Miniguides: "read" },
    villagesAdd: { Villages: "add" },
    villagesRead: { Villages: "read" }
  }),
  computed: {
    ...mapGetters(["permissions", "attractionTab", "profile", "roles"]),
    miniDesktop() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.xs) {
        return false;
      } else {
        return this.mini;
      }
    },
    role() {
      return localStorage.getItem("role");
    },
    visibleTabs() {
      return this.tabs.filter((tab) => tab.visible);
    },
    isDebug() {
      return localStorage.getItem("isDebug") == "true";
    }
  },
  watch: {
    $route: {
      handler() {
        this.itemSelected();
      }
    },
    searchKey: {
      handler() {
        this.searchResults = [];
        this.resultsCount = 0;
        this.currentPage = 1;
      }
    }
  },
  methods: {
    async reloadVillageData(villageID) {
      this.$router.push(`/villages/${villageID}`);
    },
    logout() {
      this.$store.dispatch("logout");
    },
    itemSelected(link) {
      if (link && link.children.length > 0) {
        this.tabs.forEach((tab) => {
          if (tab.routeName == link.routeName) {
            tab.active = true;
          }
        });
      } else {
        if (link) {
          this.tabs.forEach((tab) => {
            tab.children.forEach((item) => (item.active = false));
            if (tab.routeName == link.routeName) {
              this.routeName = tab.routeName;
              tab.active = true;
            }
          });
        } else {
          this.tabs.forEach((tab) => {
            if (tab.children && tab.children.length > 0) {
              tab.children.forEach((childTab) => {
                if (childTab.routeName == this.$route.name) {
                  this.routeName = childTab.routeName;
                  childTab.active = true;
                }
              });
            } else {
              tab.active = tab.routeName == this.$route.name;
            }
          });
        }
      }
    },

    async fetchRoles() {
      if (this.roles) {
        let currentRole = localStorage.getItem("role");
        this.roles.forEach((role) => {
          if (role.name.toLowerCase() == currentRole) {
            this.tabs.forEach((tab) => {
              if (tab.children.length > 0) {
                tab.visible = true;
                tab.children.forEach((chTab) => {
                  role.permissions.forEach((permission) => {
                    if (chTab.role == permission.name) {
                      chTab.visible = true;
                    }
                  });
                });
              } else {
                if (tab.role == "All") {
                  if (
                    tab.routeName == "Roles" &&
                    localStorage.getItem("role") != "admin"
                  ) {
                    tab.visible = false;
                  } else {
                    tab.visible = true;
                  }
                } else {
                  role.permissions.forEach((permission) => {
                    if (tab.role == permission.name) {
                      tab.visible = true;
                    }
                  });
                }
              }
            });
          }
        });
      }
    },

    childSelected(child, parent) {
      this.tabs.forEach((tab) => {
        if (tab.routeName == parent.routeName) {
          tab.children.forEach((childTab) => {
            if (childTab.routeName == child.routeName) {
              this.$router.push(childTab.to);
              childTab.active = true;
              this.routeName = childTab.routeName;
            } else {
              childTab.active = false;
            }
          });
        }
      });
    },
    async getProfile() {
      let { data } = await this.$axios.get("admin-host/profile");
      if (data) {
        this.$store.dispatch("setProfile", data.data);
      }
    }
  },
  async created() {
    await this.fetchRoles();
    this.itemSelected();
    this.exCreateEnable = await this.$store.dispatch(
      "checkPermission",
      this.experienceCreate
    );
  },
  beforeRouteEnter(to, _2, next) {
    next(async (vm) => {
      if (to.meta.permission != "all") {
        let enable = await vm.$store.dispatch(
          "checkPermission",
          to.meta.permission
        );
        if (enable) {
          next();
        } else {
          next({
            path: "/"
          });
        }
      } else {
        next();
      }
    });
  }
};
</script>

<style lang="scss">
.app {
  background: #fbfbfd;
  overflow: scroll;
  height: 100vh;
  display: contents !important;
  .v-main {
    width: calc(100% - 230px);
    .content {
      height: 100%;
      width: 100%;
    }
  }
  .drawer {
    .drawer-menu {
      transition: 0.4s;
    }
    .search-menu {
      z-index: 9;
      background: white;
    }
  }
  .v-menu__content {
    .v-list {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .search-menu-border {
    border: none !important;
  }
  .search-menu-border--active {
    border: 1px solid rgba($gray, 0.3) !important;
  }

  .v-toolbar__content {
    padding: 0px !important;
  }
  .main-mini {
    width: calc(100% - 56px);
  }
  .profile-item {
    border-top: 1px solid rgba($grayicon, 0.1);
    border-bottom: 1px solid rgba($grayicon, 0.1);

    img {
      border-radius: 50%;
    }
  }
  .v-list-item__icon {
    display: none;
  }
  .drawer {
    .v-list-item__content {
      padding: 0px;
    }
    .v-list-item {
      min-height: 40px;
      height: 40px;
      padding: 0px;
      margin-bottom: 10px;
    }
    .v-list-group__header {
      padding: 0px 5px;
      border-radius: 5px !important;
      cursor: default;
      .v-list-item__icon {
        display: inline-block;
        align-self: unset;
      }
      &__prepend-icon {
        margin-right: 10px !important;
      }
    }
    .v-list-group__header__append-icon {
      min-width: 20px !important;
      width: 20px !important;
    }
    .v-list-group {
      .drawer-menu {
        padding-left: 30px !important;
      }
    }
  }
  @media (max-width: 960px) {
    .v-main {
      width: 100% !important;
    }
    .drawer {
      padding-top: 65px !important;
    }
  }
}
</style>
